module.exports = {
    fadeIn: {
        data() {
            return {
                shown: false
            };
        },
        mounted: function () {
            setTimeout(() => this.shown = true, 1);
        }
    }
};