<template>
  <div>
    <line-chart :chart-data="datacollection" :options="options"></line-chart>
  </div>
</template>

<script>
import LineChart from "@/config/LineChart.js";

export default {
  name: "EtfChart",
  props: {
    datacollection: {
      type: Object,
      required: true,
    },
  },
  components: {
    LineChart,
  },
  data() {
    return {
      inversedatacollection: {},
      defaultOptions: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: "index",
        },
        tooltips: {
          enabled: true,
          callbacks: {
            labelColor: () => {
              return {
                backgroundColor: "#3E95CD",
              };
            },
            footer: (tooltipItems, data) => {
              return data.datasets[0].datum[tooltipItems[0].index];
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 0.5,
                maxTicksLimit: 40,
                max: this.datacollection?.yMax
                  ? this.datacollection?.yMax
                  : 3.0,
                min: this.datacollection?.yMin
                  ? this.datacollection?.yMin
                  : -3.0,
              },
              gridLines: {
                color: "rgba(0, 0, 0, 1)",
                lineWidth: 1,
                zeroLineWidth: 2,
                zeroLineColor: "rgba(0, 0, 0, 1)",
              },
            },
          ],
        },
      },
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    options: function () {
      /*Linien bei Wert 2, 1.5, -1.5, -2 sollen dicker und in anderer Farbe dargestellt werden.
      Da ChartJS keine Funktionen, sondern nur Werte oder Arrays bei Wertzuweisungen in data 
      unterstütz wird diese über diese computed property gesteuert. Anhand der Maximalwerte 
      wird der Index von 2, 1.5, -1.5 und -2 im Graphen gefunden und der color und lineWidth
      property ein entsprechendes Array zugewiesen.*/
      let opt = this.defaultOptions;

      let totalSteps =
        (opt.scales.yAxes[0].ticks.max - opt.scales.yAxes[0].ticks.min) * 2;

      let widthArray = [];
      let colorArray = [];

      widthArray.length = totalSteps;
      widthArray.fill(1);

      colorArray.length = totalSteps;
      colorArray.fill("#CECECE");

      let indexOfValue2 = 2 + (opt.scales.yAxes[0].ticks.max - 3) * 2;

      widthArray[indexOfValue2] =
        widthArray[indexOfValue2 + 1] =
        widthArray[indexOfValue2 + 7] =
        widthArray[indexOfValue2 + 8] =
          3;

      colorArray[indexOfValue2] =
        colorArray[indexOfValue2 + 1] =
        colorArray[indexOfValue2 + 7] =
        colorArray[indexOfValue2 + 8] =
          "rgba(195, 203, 216, 1)";

      opt.scales.yAxes[0].gridLines.lineWidth = widthArray;
      opt.scales.yAxes[0].gridLines.color = colorArray;

      return opt;
    },
  },
  methods: {},
};
</script>