<template>
  <div class="loading-overlay fade" :class="{ show: shown }">
    <div class="overlay"></div>
    <i class="fad fa-spinner fa-spin fa-3x"></i>
    <span v-if="text" style="color:blue">{{ text }}</span>
  </div>
</template>

<script>
import { fadeIn } from "@/mixins/FadeIn";

export default {
  mixins: [fadeIn],
  props: {
    text: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style>
</style>