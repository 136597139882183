import axios from 'axios';
import router from './Router'

let arveaConfig = {
	baseURL: process.env.VUE_APP_ARVEA_URL,
	defaultURL: process.env.VUE_APP_SERVER_URL,
	withCredentials: true
}

const Arvea = axios.create(arveaConfig);

Arvea.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response) {
		if (error.response.status === 401) {
			if (router.currentRoute.name !== 'login')
				router.push({ name: 'login' })
		}
	}

	return Promise.reject(error);
})

export default Arvea
