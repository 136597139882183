<template>
  <div id="StrikeEvolutionGraph">
    <line-chart :chart-data="datacollection" :options="SE_Options"></line-chart>
  </div>
</template>
	  
	  <script>
import LineChart from "@/config/LineChart.js";

export default {
  name: "StrikeEvolutionGraph",
  components: {
    LineChart,
  },
  mixins: [],
  props: {
    strikeEvolution: {
      type: Array,
      required: false,
      default: null,
    },
    yMaxStrikePrice: {
      type: Number,
      default: 0,
    },
    yMinStrikePrice: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "Strike Evolution",
    },
    type: {
      type: String,
      default: "SE",
    },
  },
  metaInfo() {
    return {};
  },
  data() {
    return {
      SE_Options: {
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title,
          fontSize: 25,
          fontColor: "#000",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize:
                  this.yMaxStrikePrice === 0
                    ? 5
                    : this.yMaxStrikePrice < 50
                    ? 5
                    : this.yMaxStrikePrice < 150
                    ? 10
                    : this.yMaxStrikePrice < 500
                    ? 30
                    : this.yMaxStrikePrice < 800
                    ? 60
                    : 100,
                maxTicksLimit: 40,
                /* max: this.yMaxStrikePrice == 0 ? 50 : this.yMaxStrikePrice, */
                min:
                  this.yMaxStrikePrice < 30
                    ? 0
                    : this.yMaxStrikePrice < 150
                    ? this.yMinStrikePrice - (this.yMinStrikePrice % 10)
                    : this.yMaxStrikePrice < 500
                    ? this.yMinStrikePrice - (this.yMinStrikePrice % 30)
                    : this.yMaxStrikePrice < 800
                    ? this.yMinStrikePrice - (this.yMinStrikePrice % 60)
                    : this.yMinStrikePrice - (this.yMinStrikePrice % 100),
                callback: function (value) {
                  return value;
                },
              },
              gridLines: {
                color: "rgba(0, 0, 0, 1)",
                lineWidth: 0.5,
                zeroLineWidth: 1,
                zeroLineColor: "rgba(0, 0, 0, 1)",
              },
            },
          ],
        },
      },
      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            inverseData: [],
            label: "Volume",
            borderColor: "#3e95cd",
            fill: false,
            datum: [],
          },
          {
            data: [],
            inverseData: [],
            label: "OI",
            borderColor: "#eb8334",
            fill: false,
            datum: [],
          },
        ],
      },
    };
  },

  watch: {},
  computed: {},
  created() {
    this.setDatacollection();
  },
  mounted() {},
  methods: {
    setDatacollection() {
      if (this.type == "SE") {
        //SE
        this.datacollection.datasets[0].data = [];
        this.datacollection.labels = [];

        this.strikeEvolution.forEach((strikeEvolution) => {
          this.datacollection.datasets[0].data.push(strikeEvolution.volume);
          this.datacollection.datasets[1].data.push(strikeEvolution.oi);
          this.datacollection.labels.push(strikeEvolution.expiration_date);
        });
      }
    },
  },
};
</script>
	  
	  <style lang="scss">
#LPPEvolutionGraph {
  padding: 0px 40px 40px;
}
#LPPEvolutionGraph > div {
  height: 450px;
}
</style>
	